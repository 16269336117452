import PropTypes from "prop-types";
import React from "react";

const SEO = ({ title }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title ? `${title} | CHAPZ` : "CHAPZ"}</title>
      <meta name="robots" content="noindex, follow" />
      <meta
        name="description"
        content="CHAPZ is a digital agency that specializes in web development, mobile app development, and digital marketing."
      />
      <meta
        name="keywords"
        content="web development, mobile app development, digital marketing, digital agency, web design, mobile app design, digital marketing agency, web development company, mobile app development company, digital marketing company, web design company, mobile app design company, digital marketing agency"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="author" content="CHAPZ" />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
