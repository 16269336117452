import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    image: "/images/service/serviice-01.jpg",
    title: "Product/Solution Design",
    description: `<ul>
        <li>Problem Identification</li>
        <li>Research and analysis</li>
        <li>UX/UI Design</li>
        <li>Consultancy</li>
      </ul>`,
  },
  {
    image: "/images/service/serviice-02.jpg",
    title: "Development",
    description: `<ul>
        <li>Web development</li>
        <li>Mobile development</li>
        <li>API & Microservices</li>
        <li>System integration</li>
      </ul>`,
  },
  {
    image: "/images/service/serviice-03.jpg",
    title: "AI Software development",
    description: `<ul>
        <li>AI & Machine learning</li>
        <li>Data transformation</li>
        <li>AI consultancy</li>
      </ul>`,
  },
  {
    image: "/images/service/serviice-04.jpg",
    title: "Cloud solutions",
    description: `<ul>
        <li>Infrastructure as a Service</li>
        <li>Software as a service</li>
        <li>Cloud consultancy</li>
      </ul>`,
  },
];
const ServiceTwo = ({ textAlign, cardStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`card-box ${cardStyle} ${textAlign}`}>
              <div
                className="inner"
                style={{
                  height: "calc(100% - 40px)",
                  maxHeight: "500px",
                  minHeight: "400px",
                }}
              >
                <div className="image">
                  <Link to="#service">
                    <img src={`${val.image}`} alt="card Images" />
                  </Link>
                </div>
                <div className="content">
                  <h4
                    className="title mb--20"
                    style={{
                      fontSize: "1.8rem",
                      fontWeight: "600",
                    }}
                  >
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                  {/* <Link
                    className="btn-default btn-small btn-border"
                    to="#service"
                  >
                    Read More
                  </Link> */}
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceTwo;
