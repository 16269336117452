import React from "react";

const ButtonGroup = ({ options = [], value, onClick, multiple, isError }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "24px",
      }}
    >
      {multiple
        ? options.map((option) => (
            <button
              key={option.value}
              type="button"
              className={`btn-default btn-large rounded-pill ${
                value.includes(option.value) ? "" : "btn-border"
              }`}
              style={{
                ...(isError && {
                  border: "1px solid #e33f78",
                }),
              }}
              onClick={() => onClick(option.value)}
            >
              {option.name}
            </button>
          ))
        : options.map((option) => (
            <button
              key={option.value}
              type="button"
              className={`btn-default btn-large rounded-pill ${
                value !== option.value ? "btn-border" : ""
              }`}
              style={{
                ...(isError && {
                  border: "1px solid #e33f78",
                }),
              }}
              onClick={() => onClick(option.value)}
            >
              {option.name}
            </button>
          ))}
    </div>
  );
};

export default ButtonGroup;
