import React from "react";
import Typed from "react-typed";

import SEO from "../common/SEO";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BrandFour from "../elements/brand/BrandFour";
import Layout from "../common/Layout";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Mission from "../elements/about/Mission";
import Separator from "../elements/separator/Separator";
import ServiceTwo from "../elements/service/ServiceTwo";
import TestimonialThree from "../elements/testimonial/TestimonialThree";

const Home = () => {
  return (
    <>
      <SEO title="" />
      <Layout>
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-500">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">
                    Digital Transformation & Software Development
                  </span>
                  <h1 className="title theme-gradient display-two">
                    Our Services <br />{" "}
                    <Typed
                      strings={[
                        "Product Development",
                        "Web Development",
                        "Mobile Development",
                        "UX UI Design",
                        "Cloud Solutions",
                        "Data and Analytics",
                        "System Integration",
                        "Machine Learning & AI",
                        "Consultation",
                        "Training",
                      ]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">Your Success as Our Success</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Service Area  */}
        <div className="rn-service-area" id="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we can do for you"
                  title="Our Services"
                  description="
                    <p>
                      Leveraging our expertise and commitment, we specialize in
                      the transformation of your business concepts into
                      high-quality, innovative digital solutions that drive
                      growth and success.
                    </p>
                  "
                />
              </div>
            </div>
            {/* <Service
              serviceStyle="service__style--1  radius mt--25"
              textAlign="text-center"
            /> */}
            <ServiceTwo cardStyle="card-style-1" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}
        <div className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="We are here to help you"
                  title="Our Process"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo />
              </div>
            </div>
          </div>
        </div>
        <div className="rwt-brand-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Awesome Client"
                  title="Trusted by"
                  description=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt--10">
                <BrandFour brandStyle="brand-style-2 variation-2" />
              </div>
            </div>
          </div>
        </div>
        <div className="main-content" id="works">
          <div className="rwt-portfolio-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    title="Our Works"
                    subtitle="We have worked with some awesome companies and businesses"
                    description=""
                  />
                </div>
              </div>
              {/* <PortfolioOne Column="col-lg-6 col-md-6 mt--30 box-grid-layout no-overlay" /> */}
              <TestimonialThree teamStyle="" />
            </div>
          </div>
        </div>
        <Separator />
        <Mission />
        {/* Start Call To Action Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
