import React from "react";
import { MdEmail, MdPhone } from "react-icons/md";

import Logo from "../../elements/logo/Logo";
import ScrollTop from "./ScrollTop";
import { scrollToSection } from "../../utils/scrollToSection";

const FooterOne = () => {
  const services = [
    {
      text: "Consulting",
      url: "#services",
    },
    {
      text: "Web Development",
      url: "#services",
    },
    {
      text: "Mobile App Development",
      url: "#services",
    },
    {
      text: "UX UI Design",
      url: "#services",
    },
    {
      text: "Data & Analytics",
      url: "#services",
    },
    {
      text: "Infrastructure & DevOps",
      url: "#services",
    },
    {
      text: "Digital solution & System architecture",
      url: "#services",
    },
    {
      text: "Machine Learning & AI",
      url: "#services",
    },
  ];

  return (
    <>
      <footer className="rn-footer footer-style-default">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* Start Single Widget  */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="logo">
                    <Logo
                      image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                      image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                    />
                  </div>
                  <div className="inner">
                    <p>Your Success as Our Success</p>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    {/* Start Single Widget  */}
                    <div className="rn-footer-widget">
                      <div className="widget-menu-top">
                        <h4 className="title">Services</h4>
                        <div className="inner">
                          <ul className="footer-link link-hover">
                            {services.map((data, index) => (
                              <li key={index}>
                                <a
                                  href={`${data.url}`}
                                  onClick={scrollToSection}
                                >
                                  {data.text}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Start Single Widget  */}
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="rn-footer-widget">
                      <h4 className="title">Contact</h4>
                      <div className="inner">
                        <ul className="footer-link link-hover">
                          <li>
                            <MdEmail />
                            <a
                              href="mailto:info@chapz.co.th"
                              style={{ marginLeft: "5px" }}
                            >
                              info@chapz.co.th
                            </a>
                          </li>
                          <li>
                            <MdPhone />
                            <a
                              href="tel:+66619396886"
                              style={{ marginLeft: "5px" }}
                            >
                              +66 61 939 6886
                            </a>
                          </li>
                          <li>
                            <MdPhone />
                            <a
                              href="tel:+66805956458"
                              style={{ marginLeft: "5px" }}
                            >
                              +66 80 595 6458
                            </a>
                          </li>
                        </ul>
                        {/* <p
                          className="subtitle"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <MdEmail /> <span>info@chapz.co.th</span>
                        </p>
                        <p
                          className="subtitle"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <MdEmail /> <span>info@chapz.co.th</span>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* End Single Widget  */}
              </div>
              {/* End Single Widget  */}
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterOne;
