import React from "react";

import Header from "../common/header/HeaderOne";
import Footer from "./footer/FooterOne";
import Copyright from "./footer/Copyright";

const Layout = ({ children }) => {
  return (
    <>
      <main className="page-wrapper">
        <Header btnStyle="round" HeaderSTyle="header-not-transparent" />
        {children}
        <Footer />
        <Copyright />
      </main>
    </>
  );
};
export default Layout;
