import React from "react";
import ContactForm from "./ContactForm";

const ContactOne = () => {
  return (
    <div className="row mt--40 row--15">
      <ContactForm formStyle="contact-form-1" />
    </div>
  );
};
export default ContactOne;
