import React, { useState } from "react";
import emailjs from "emailjs-com";
import ButtonGroup from "./ButtonGroup";
import SentMessage from "./SentMessage";

function ContactForm({ props, formStyle }) {
  const [services, setServices] = useState([]);
  const [budget, setBudget] = useState("");
  const [hasError, setHasError] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if (services.length === 0) {
      setHasError(true);
      return;
    } else {
      setHasError(false);
    }

    if (budget === "") {
      setHasError(true);
      return;
    } else {
      setHasError(false);
    }

    emailjs
      .sendForm("chapz", "template_nrr1jql", e.target, "fYxN4W7bJEJ5_YARW")
      .then(
        (result) => {
          setSuccess(true);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  const handleSelectService = (service) => {
    if (services.includes(service)) {
      setServices(services.filter((item) => item !== service));
    } else {
      setServices([...services, service]);
    }
  };

  const handleSelectBudget = (budget) => {
    setBudget(budget);
  };

  return (
    <>
      {success ? (
        <SentMessage />
      ) : (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
          <div
            style={{
              background: "#000000",
              opacity: 0.7,
              borderRadius: "10px",
              padding: "32px",
              marginBottom: "20px",
            }}
          >
            <h4>Tell us about yourself</h4>
            <div className="form-group">
              <input
                type="text"
                name="full_name"
                placeholder="Full Name*"
                required
              />
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    required
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <input
                    type="tel"
                    name="phone_number"
                    inputMode="numeric"
                    placeholder="Phone Number*"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <input type="text" name="company" placeholder="Company Name" />
            </div>
          </div>
          <div
            style={{
              background: "#000000",
              opacity: 0.7,
              borderRadius: "10px",
              padding: "32px",
              marginBottom: "20px",
            }}
          >
            <h4>What services are you interested in?</h4>
            <p>
              Please be specific about the service so we can have more targeted
              topics to discuss with you*
            </p>
            <ButtonGroup
              onClick={handleSelectService}
              value={services}
              multiple
              isError={services.length === 0 && hasError}
              options={[
                {
                  name: "Consulting",
                  value: "consulting",
                },
                {
                  name: "Web Development",
                  value: "web_development",
                },
                {
                  name: "Mobile App Development",
                  value: "mobile_app_development",
                },
                {
                  name: "UX UI Design",
                  value: "ux_ui_design",
                },
                {
                  name: "Data & Analytics",
                  value: "data_analytics",
                },
                {
                  name: "Infrastructure & DevOps",
                  value: "infrastructure_devops",
                },
                {
                  name: "Digital solution & System architecture",
                  value: "digital_solution_system_architecture",
                },
                {
                  name: "Machine Learning & AI",
                  value: "machine_learning_ai",
                },
              ]}
            />
            <input type="hidden" name="services" value={services}></input>
          </div>
          <div
            style={{
              background: "#000000",
              opacity: 0.7,
              borderRadius: "10px",
              padding: "32px",
              marginBottom: "20px",
            }}
          >
            <h4>Tell us about your project</h4>
            <div className="form-group">
              <textarea
                name="project_requirement"
                placeholder="Please describe your project in detail, goals, target audience, etc.*"
                required
              ></textarea>
            </div>
          </div>
          <div
            style={{
              background: "#000000",
              opacity: 0.7,
              borderRadius: "10px",
              padding: "32px",
              marginBottom: "20px",
            }}
          >
            <h4>Project budget (THB)</h4>
            <p>
              Please be specific the budget so we can provide you more useful
              advice*
            </p>
            <ButtonGroup
              onClick={handleSelectBudget}
              value={budget}
              isError={budget === "" && hasError}
              options={[
                {
                  name: "Less than 250,000",
                  value: "น้อยกว่า 250000",
                },
                {
                  name: "250,000 - 500,000",
                  value: "250000-500000",
                },
                {
                  name: "500,000 - 1,000,000",
                  value: "500000-1000000",
                },
                {
                  name: "1,000,000 - 2,000,000",
                  value: "1000000-2000000",
                },
                {
                  name: "2,000,000 - 5,000,000",
                  value: "2000000-5000000",
                },
                {
                  name: "More than 5,000,000",
                  value: "มากกว่า 5000000",
                },
              ]}
            />
            <input type="hidden" name="budget" value={budget}></input>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <div className="form-group">
              <button className="btn-default btn-large">Send Message</button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
export default ContactForm;
