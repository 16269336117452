import React from "react";

const SentMessage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p
        style={{
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        Your message has been successfully sent.
      </p>
      <p
        style={{
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        We will contact you soon.
      </p>
    </div>
  );
};

export default SentMessage;
