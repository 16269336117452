import React from "react";

const BrandList = [
  {
    image: "./images/our-clients/seekforce.webp",
  },
  {
    image: "./images/our-clients/seekster.webp",
  },
  // {
  //   image: "./images/our-clients/hof.webp",
  // },
  {
    image: "./images/our-clients/work-motion.webp",
  },
  {
    image: "./images/our-clients/the-mather.webp",
  },
  {
    image: "./images/our-clients/genie.webp",
  },
  {
    image: "./images/our-clients/aris.webp",
  },
  {
    image: "./images/our-clients/digipop.webp",
  },
];

const BrandFour = ({ brandStyle }) => {
  return (
    <ul className={`brand-list ${brandStyle}`}>
      {BrandList.map((data, index) => (
        <li key={index}>
          <img
            className="brand-image"
            src={`${data.image}`}
            alt="Brand Image"
            style={{
              borderRadius: "100%",
              backgroundColor: "#fff",
              padding: "6px",
            }}
          />
        </li>
      ))}
    </ul>
  );
};

export default BrandFour;
