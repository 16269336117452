import React from "react";

const Separator = () => {
  return (
    <div className="rbt-separator-mid">
      <div className="container">
        <hr
          className="rbt-separator m-0"
          style={{
            borderColor: "#ffffff",
          }}
        />
      </div>
    </div>
  );
};

export default Separator;
