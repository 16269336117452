import React from "react";
import PropTypes from "prop-types";

const TechStackItem = ({ name }) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "5px",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <img
        src={`/assets/icons/${name}.svg`}
        alt={name}
        style={{ width: "32px", height: "32px" }}
      />
    </div>
  );
};

TechStackItem.propTypes = {
  name: PropTypes.string,
};

export default TechStackItem;
