import React from "react";
import Slider from "react-slick";
import { slickDot } from "../../utils/script";
import TechStackItem from "../portfolio/TechStackItem";

const ourWorks = [
  {
    image: "images/portfolio/yala/cover.webp",
    title: "Yala Market Delivery",
    subtitle: "Delivery service for local markets",
    category: "development",
    techStacks: ["reactjs", "rails", "nodejs", "postgres", "redis", "firebase"],
  },
  // {
  //   image: "images/portfolio/state-railway/image1.png",
  //   title: "Trip Booking System for The State Railway of Thailand",
  //   subtitle:
  //     "Design a user-friendly trip booking system with intuitive interfaces, displaying trip details and tourist information, and offering options to view and print as a PDF.",
  //   category: "development",
  //   techStacks: ["figma", "ai", "lottiefiles"],
  // },
  // {
  //   image: "images/portfolio/state-railway/image1.png",
  //   title: "ออกแบบระบบจองทริปสำหรับการรถไฟแห่งประเทศไทย",
  //   subtitle:
  //     "ออกแบบระบบจองทริปที่ใช้งานง่าย ด้วยอินเทอร์เฟซที่เข้าใจง่าย แสดงรายละเอียดการเดินทางและข้อมูลสถานที่ท่องเที่ยว พร้อมทั้งมีตัวเลือกในการดูและพิมพ์เป็น PDF",
  //   category: "development",
  //   techStacks: ["figma", "ai", "lottiefiles"],
  // },
  {
    image: "images/portfolio/genie/genie-live.webp",
    title: "Genie Live",
    subtitle: "Live streaming platform",
    client: "Rainbow Themes",
    category: "development",
    techStacks: ["nextjs", "nestjs", "postgres", "redis", "aws"],
  },
  {
    image: "images/portfolio/genie/genie-crm.webp",
    title: "Genie CRM",
    subtitle: "Customer relationship management platform",
    client: "Rainbow Themes",
    category: "development",
    techStacks: ["reactjs", "nodejs", "postgres", "redis", "aws"],
  },
  {
    image: "images/portfolio/digipop/laptop.webp",
    title: "DigiPop",
    subtitle: "Dutch auction commerce platform",
    client: "Rainbow Themes",
    category: "development",
    techStacks: ["nextjs", "nodejs", "postgres", "redis", "aws"],
  },
  {
    image: "images/portfolio/face-scan/face-scan.webp",
    title: "Face and Eye detection",
    subtitle:
      "Utilizing Haar Cascades Drowsiness Detection System While Driving: Employing Eye Detection",
    client: "Rainbow Themes",
    category: "development",
    techStacks: ["python", "postgres"],
  },
];

const TestimonialThree = ({ teamStyle }) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <Slider className="slick-space-gutter--15 rn-slick-arrow" {...slickDot}>
          {ourWorks.map((ourWork, index) => (
            <div key={index} className={`testimonial-style-two ${teamStyle}`}>
              <div className="row align-items-center row--20">
                <div className="order-2 order-md-2 col-lg-4 col-md-6">
                  <div className="content mt_sm--40">
                    <span className="form">{ourWork.title}</span>
                    <p className="description">{ourWork.subtitle}</p>
                    <div
                      style={{
                        width: "10%",
                        height: "1px",
                        backgroundColor: "#979797",
                        margin: "20px 0",
                      }}
                    />
                    <div
                      className="client-info"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                      }}
                    >
                      {ourWork.techStacks.map((stack) => (
                        <TechStackItem name={stack} key={stack} />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="order-1 order-md-1 col-lg-5 col-md-8 offset-lg-2">
                  <div className="thumbnail">
                    <img src={ourWork.image} alt={ourWork.title} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default TestimonialThree;
