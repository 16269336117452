import React from "react";
import Typed from "react-typed";
import AccordionOne from "../accordion/AccordionOne";

const Mission = () => {
  return (
    <div className="rn-company-mission-are rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-6">
            <div className="mission-title px-4 px-md-0">
              <h2 className="title">
                Why Choose Us
                <br />{" "}
                <Typed
                  className="theme-gradient"
                  strings={[
                    "Expertise",
                    "Specialization",
                    "Latest Technologies",
                    "Unique Needs",
                    "Transparent Pricing",
                    "Sustainable Growth",
                  ]}
                  typeSpeed={80}
                  backSpeed={5}
                  backDelay={1000}
                  loop
                />
              </h2>
              <p style={{ fontSize: "1.8rem" }}>
                We empower every organization and individual with
                technology-driven solutions to realize their visions. Let Chapz
                break barriers, catalyze transformation, and unlock potential.
              </p>
              <p style={{ fontSize: "1.8rem" }}>
                We believe in the principle that "Your success is our success."
                We are committed to being a trusted partner for every business
                venturing into the digital world by providing customized
                solutions tailored to meet specific needs and empowering you to
                thrive in the digital era.
              </p>
            </div>
          </div>
          <div className="col-lg-6 mt_md--30 mt_sm--30">
            <AccordionOne customStyle="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Mission;
