export const scrollToSection = (e) => {
  e.preventDefault();
  const href = e.target.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop;

  window.scroll({
    top: offsetTop,
    behavior: "smooth",
    block: "nearest",
  });
};
